<template>
  <div class="formBox">
    <div style="display: flex; align-items: center">
      <h4 style="color: #146aff; padding: 0px 20px 0px 30px">活动信息</h4>
    </div>
    <el-form ref="form" :model="form" label-width="120px" :rules="updatePwdRules">
      <el-form-item label="活动图片" prop="image">
        <div class="cover">
          <div class="img_box">
            <img-big-upload
              :imageShow.sync="form.image"
              @uploadOneImgShow="uploadOneImg"
              @removeImg="removeImg"
            ></img-big-upload>
            <span style="color: #146aff">建议上传图片整体尺寸为375*160px，应小于8MB</span>
          </div>
          <div v-if="form.image">缩略图</div>
          <div v-if="form.image" class="thumbnail">
            <div class="thumbnail_box">
              <img :src="form.image" alt="" />
              <div>图片尺寸：123*83 pxx</div>
              <div>图片大小：4MB</div>
              <div>展示位置：首页</div>
            </div>
            <div class="thumbnail_box">
              <img :src="form.image" alt="" />
              <div>图片尺寸：375*160 px</div>
              <div>图片大小：5MB</div>
              <div>展示位置：文章详情页</div>
            </div>
            <div class="thumbnail_box">
              <img :src="form.image" alt="" />
              <div>图片尺寸：442*226 px</div>
              <div>图片大小：10MB</div>
              <div>展示位置：首页banner区域</div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="活动名称" prop="activityName">
        <!-- <el-input
          @blur="activityNameBlur"
          v-model="form.activityName"
          placeholder="请输入活动名称"
        ></el-input> -->
        <el-autocomplete
          v-model="form.activityName"
          :fetch-suggestions="querySearchActivityName"
          placeholder="请输入政策标题"
          @select="handleSelectActivityName"
        >
          <template slot-scope="{ item }">
            <div class="name">
              <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                item.activityName
              }}</span>
            </div>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="一句话介绍:" prop="activityBrief">
        <div class="txtree">
          <el-input
            v-model="form.activityBrief"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            placeholder="请输入一句话简介"
            maxlength="100"
            show-word-limit
          />
        </div>
      </el-form-item>

      <el-form-item label="封面图" prop="activityImg">
        <div class="cover">
          <div class="img_box">
            <img-big-upload
              :imageShow.sync="form.activityImg"
              :addTitleShow="false"
              @removeImg="removeImgDianspeci"
              @uploadOneImgShow="uploadOneImgShowDianspeci"
            />
            <span style="color: #146aff">建议图片整体尺寸为323*184 px大小不超过12MB</span>
          </div>
          <div v-if="form.activityImg">缩略图</div>
          <div v-if="form.activityImg" class="thumbnail">
            <div class="thumbnail_box">
              <img style="width: 123px; height: 83px;" :src="form.activityImg" alt="" />
              <div>图片尺寸：123*83 px</div>
              <div>图片大小：4MB</div>
              <div>展示位置：首页</div>
            </div>

            <div class="thumbnail_box">
              <img style="width: 123px; height: 83px;" :src="form.activityImg" alt="" />
              <div>图片尺寸：323*184px</div>
              <div>图片大小：10MB</div>
              <div>展示位置：活动列表封面</div>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="参与人" prop="participant">
        <el-checkbox-group v-model="form.participant">
          <el-checkbox :label="'普通用户'">普通用户</el-checkbox>
          <el-checkbox :label="'个人会员'">个人会员</el-checkbox>
          <el-checkbox :label="'企业会员'">企业会员</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="参与方式" prop="activityType">
        <el-radio-group v-model="form.activityType">
          <el-radio :label="1" :value="1">线上</el-radio>
          <el-radio :label="2" :value="2">线下</el-radio>
          <el-radio :label="3" :value="3">线上+线下</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="活动城市" prop="activityProvince" class="informationType">
        <div>
          <el-select v-model="form.activityProvince" placeholder="请选择省份" @change="citychange">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="form.activityCity" placeholder="请选择地区">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingLists"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="详细地址" prop="activityAddress">
        <el-input v-model="form.activityAddress" placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <el-form-item label="活动时间" prop="date">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="form.date"
          @change="checkParamsData"
          type="datetimerange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="活动名额" prop="activityQuota">
        <el-input v-model="form.activityQuota" placeholder="请输入活动名额"></el-input>
      </el-form-item>
      <el-form-item label="活动类别" prop="isPlatform">
        <el-radio-group v-model="form.isPlatform">
          <el-radio :label="'1'">平台活动</el-radio>
          <el-radio :label="'2'">渠道活动</el-radio>
        </el-radio-group>
        <el-checkbox-group v-model="form.activityTypes" v-show="form.isPlatform">
          <el-checkbox label="技术类"></el-checkbox>
          <el-checkbox label="产业类"></el-checkbox>
          <el-checkbox label="其他"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="活动描述" prop="activityIntro">
        <div class="editBarBox">
          <div class="editBar">
            <Editorbar v-model="form.activityIntro" :isClear="isClear" />
            <div class="img_tips" style="margin-top: 10px">
              <span
                >(图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
              </span>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="主办方" prop="sponsor">
        <el-input v-model="form.sponsor" placeholder="请输入主办方"></el-input>
      </el-form-item>

      <h4 class="h4_share">附加信息</h4>
      <div>
        <el-form-item label="活动票种:" prop="">
          <div class="tiket_flex" v-for="(item, index) in form.activityPrices" :key="index">
            <div class="ticket_Type">
              <div class="original">
                <el-form-item label="票名" prop="ticketName" label-width="40px">
                  <el-input v-model="item.ticketName" placeholder="请输入票名"></el-input>
                </el-form-item>
                <el-form-item label="票数" prop="votes" label-width="40px">
                  <el-input v-model="item.votes" placeholder="请输入票数"></el-input>
                </el-form-item>
                <el-form-item label="票价" prop="price" label-width="40px">
                  <el-input v-model="item.price" placeholder="请输入票价"></el-input>
                </el-form-item>
              </div>
              <div style="position: relative; left: -80px">
                <el-form-item label="是否单人单张" prop="isSingle" label-width="120px">
                  <el-radio-group v-model="item.isSingle">
                    <el-radio :label="'1'" :value="'1'">是</el-radio>
                    <el-radio :label="'0'" :value="'0'">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="original">
                <el-form-item label="备注" label-width="40px">
                  <el-input v-model="item.notes" placeholder="备注"></el-input>
                </el-form-item>
              </div>
              <div class="add_information">
                <div style="padding: 20px">普通用户</div>
                <div class="online_conference">
                  <el-form-item label="费用类型" prop="">
                    <div>
                      <el-radio-group v-model="item.isMoney">
                        <el-radio :label="1">付费</el-radio>
                        <el-radio :label="2">免费</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>

                  <el-form-item v-if="item.isMoney == '1'" label="优惠方式" prop="userDiscountType">
                    <div>
                      <el-radio-group v-model="item.userDiscountType">
                        <el-radio :label="'折扣'">折扣</el-radio>
                        <el-radio :label="'优惠价'">优惠价</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-if="item.isMoney == '1' && item.userDiscountType == '折扣'"
                    label="折扣"
                    prop="discount"
                  >
                    <div class="original">
                      <el-input-number
                        v-model="item.userDiscount"
                        :min="1"
                        :max="100"
                        label="描述文字"
                      ></el-input-number>
                      <span class="online_color">%</span>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-if="item.isMoney == '1' && item.userDiscountType == '优惠价'"
                    label="优惠价"
                    prop="discount"
                  >
                    <div class="original">
                      <el-input v-model="item.userDiscount" placeholder="请输入原价"></el-input>
                      <span class="online_color">元</span>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div class="add_information">
                <div style="padding: 20px">个人会员用户</div>
                <div class="online_conference">
                  <el-form-item label="费用类型" prop="">
                    <div>
                      <el-radio-group v-model="item.memberChargeWay">
                        <el-radio :label="1">付费</el-radio>
                        <el-radio :label="2">免费</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>

                  <el-form-item
                    v-if="item.memberChargeWay == '1'"
                    label="优惠方式"
                    prop="userDiscountType"
                  >
                    <div>
                      <el-radio-group v-model="item.memberDiscountType">
                        <el-radio :label="'折扣'">折扣</el-radio>
                        <el-radio :label="'优惠价'">优惠价</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-if="item.memberChargeWay == '1' && item.memberDiscountType == '折扣'"
                    label="折扣"
                    prop="discount"
                  >
                    <div class="original">
                      <el-input-number
                        v-model="item.memberDiscount"
                        :min="1"
                        :max="100"
                        label="描述文字"
                      ></el-input-number>
                      <span class="online_color">%</span>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-if="item.memberChargeWay == '1' && item.memberDiscountType == '优惠价'"
                    label="优惠价"
                    prop="discount"
                  >
                    <div class="original">
                      <el-input v-model="item.memberDiscount" placeholder="请输入原价"></el-input>
                      <span class="online_color">元</span>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div class="add_information">
                <div style="padding: 20px">企业会员用户</div>
                <div class="online_conference">
                  <el-form-item label="费用类型" prop="">
                    <div>
                      <el-radio-group v-model="item.companyChargeWay">
                        <el-radio :label="1">付费</el-radio>
                        <el-radio :label="2">免费</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>

                  <el-form-item
                    v-if="item.companyChargeWay == '1'"
                    label="优惠方式"
                    prop="userDiscountType"
                  >
                    <div>
                      <el-radio-group v-model="item.companyDiscountType">
                        <el-radio :label="'折扣'">折扣</el-radio>
                        <el-radio :label="'优惠价'">优惠价</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-if="item.companyChargeWay == '1' && item.companyDiscountType == '折扣'"
                    label="折扣"
                    prop="discount"
                  >
                    <div class="original">
                      <el-input-number
                        v-model="item.companyDiscount"
                        :min="1"
                        :max="100"
                        label="描述文字"
                      ></el-input-number>
                      <span class="online_color">%</span>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-if="item.companyChargeWay == '1' && item.companyDiscountType == '优惠价'"
                    label="优惠价"
                    prop="discount"
                  >
                    <div class="original">
                      <el-input v-model="item.companyDiscount" placeholder="请输入原价"></el-input>
                      <span class="online_color">元</span>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div style="display: flex; font-size: 24px; margin-left: 20px; margin-top: 8px">
              <span v-if="index == 0" style="color: #4e93fb" @click="addareaspeci"
                ><i class="el-icon-circle-plus"></i
              ></span>
              <span v-else style="color: #ff7575" @click="delareaspeci(index)"
                ><i class="el-icon-delete"></i
              ></span>
            </div>
          </div>
        </el-form-item>
      </div>
      <h4 class="h4_share">小程序分享链接信息</h4>
      <el-form-item label="分享链接标语:" prop="">
        <el-input v-model="form.appLinkSlogan" placeholder="请输入分享链接标语" />
      </el-form-item>
      <el-form-item label="分享链接图片:" prop="">
        <div class="pictures_box_left">
          <img-big-upload
            :imageShow.sync="form.appLinkPicture"
            :addTitleShow="false"
            @removeImg="removeImgDians"
            @uploadOneImgShow="uploadOneImgShowDians"
          />
        </div>
        <div class="img_tips">
          <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB) </span>
        </div>
      </el-form-item>
    </el-form>
    <!-- <div class="editInformation" v-if="activeId">
        <div class="item">编辑人：{{ form.createId }}</div>
        <div class="item">编辑时间：{{ form.createTime }}</div>
        <div class="item">发送人：{{ form.updateId }}</div>
        <div class="item">发送时间：{{ form.updateTime }}</div>
      </div> -->
    <div class="button_box">
      <!-- <div class="send button" @click="clickSend">发送</div>
        <div class="preview button" @click="clickPreview">预览</div> -->
      <el-button :disabled="disabled" class="save button" @click="clickSave">保存</el-button>
      <div class="cancel button" @click="clickCancel">取消</div>
    </div>
  </div>
</template>
<script>
import {
  getProvince,
  save,
  selectById,
  update,
  insertActivityForm,
  checkActivityName,
  getSystemDataParam
} from '@/api/activity.js'

import Editorbar from '../../../components/Editorbar.vue'
export default {
  components: { Editorbar },
  props: {
    activeId: {
      type: String
    }
  },
  data() {
    return {
      value: '',
      operatingList: [], //省
      operatingLists: [], //市
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      form: {
        date: [],
        appLinkPicture: '',
        activityTypes: [],
        activityAddress: '',
        activityCity: '',
        activityEndTime: '',
        activityImg: '',
        activityIntro: '',
        activityLink: '',
        activityName: '',
        image: '',
        type: [],
        activityPrices: [
          {
            activityId: this.activeId,
            companyChargeWay: '',
            companyDiscount: 0,
            companyDiscountType: '',
            id: 0,
            isMoney: '',
            isSingle: '1',
            memberChargeWay: '',
            memberDiscount: 0,
            memberDiscountType: '',
            notes: '',
            price: 0,
            ticketName: '',
            userDiscount: 0,
            userDiscountType: '',
            voteType: '',
            votes: 0
          }
        ],
        activityProvince: '',
        activityQuota: undefined,
        activityStartTime: '',
        activityType: 1,
        id: null,
        isPlatform: '',
        participant: [],
        sponsor: '',
        activityBrief: null
      },
      checked: false, //通用版
      // logo图片
      imageOneShow: '',
      imageUrlList: [],
      isClear: false,
      updatePwdRules: {
        activityBrief: [{ required: true, message: '请输入一句话介绍', trigger: 'blur' }],
        activityName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        activityType: [{ required: true, message: '请选择活动类型', trigger: 'blur' }],
        date: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        sponsor: [{ required: true, message: '请输入主办方', trigger: 'blur' }],
        informationBrief: [{ required: true, message: '请输入一句话简介', trigger: 'blur' }],
        issueName: [{ required: true, message: '请输入发布机构/人' }],
        informationType: [{ required: true, message: '请输入活动类型', trigger: 'change' }],
        notes: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        activityIntro: [{ required: true, message: '请输入活动描述', trigger: 'change' }],
        activityImg: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        logo: [{ required: true, message: '请上传logo', trigger: 'blur' }]
      },
      issueNameOption: [],
      facilitatorOption: [],
      activityNameOption: [],
      disabled: false //点击保存禁用按钮
    }
  },
  computed: {},
  watch: {},
  created() {
    this.incity()
    if (this.activeId) {
      this.getSelectById()
      this.imageOneShow = this.form.logo
    }
  },

  mounted() {},
  //方法集合
  methods: {
    // 活动名称
    async querySearchActivityName(queryString, cb) {
      if (this.form.activityName) {
        const { data: res } = await getSystemDataParam({ activityName: this.form.activityName })
        if (res.resultCode == 200) {
          this.activityNameOption = res.data
          cb(this.activityNameOption)
        }
      } else {
        cb(this.activityNameOption)
      }
    },
    async handleSelectActivityName(item) {
      this.form.activityName = item.activityName
      if (this.form.activityName) {
        const { data: res } = await checkActivityName({
          activityName: this.form.activityName
        })
        if (res.resultCode == 500) {
          this.$message.warning(res.message)
          this.form.activityName = ''
        }
      }
      for (let key in item) {
        if (!this.activeId) {
          if (item[key] !== null && item[key] !== undefined) {
            this.form[key] = item[key]
            this.$set(this.form, 'date', [item.activityStartTime, item.activityEndTime])
            this.$set(this.form, 'id', item.id)
            this.form.activityTypes = item.activityType
            this.form.activityImg = item.activityImage
            if (item.activityMode == '线上') {
              this.form.activityType = 1
            } else if (item.activityMode == '线下') {
              this.form.activityType = 2
            } else {
              this.form.activityType = 3
            }
          }
        }
      }
    },
    // 活动名称失去焦点
    // async activityNameBlur() {},
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.form.activityProvince == o.name) {
          this.form.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }
          this.search()
          // console.log(item, 'item', this.operatingList)
          // this.getByFatherIdt(o.code)
        }
      })
    },
    checkParamsData(val) {
      // console.log(val, 'checkParamsData', this.form.date)
      this.form.activityStartTime = val[0]
      this.form.activityEndTime = val[1]
    },
    // 富文本编辑器的内容
    inputVla(val) {
      // console.log(val)
    },
    async getSelectById() {
      const { data: res } = await selectById({ id: this.activeId })
      // console.log(res)
      if (res.resultCode == 200) {
        this.form = res.data
        if (!this.form.activityTypes) {
          this.form.activityTypes = []
        }
        if (!this.form.image) {
          this.form.image = ''
        }
        //参与人
        if (this.form.participant) {
          this.form.participant = this.form.participant.split(',')
        } else {
          this.form.participant = []
        }

        this.form.appLinkPicture = this.form.appLinkPicture ? this.form.appLinkPicture : ''
        // this.form.date = [this.form.activityStartTime, this.form.activityEndTime]
        this.$set(this.form, 'date', [this.form.activityStartTime, this.form.activityEndTime])
        if (!this.form.activityPrices) {
          this.form.activityPrices = [
            {
              activityId: this.activeId,
              companyChargeWay: '',
              companyDiscount: 0,
              companyDiscountType: '',
              id: 0,
              isMoney: '',
              isSingle: '1',
              memberChargeWay: '',
              memberDiscount: 0,
              memberDiscountType: '',
              notes: '',
              price: 0,
              ticketName: '',
              userDiscount: 0,
              userDiscountType: '',
              voteType: '',
              votes: 0
            }
          ]
        }
      }
    },

    //新增
    addareaspeci() {
      this.form.activityPrices.push({
        activityId: this.activeId,
        companyChargeWay: '',
        companyDiscount: 0,
        companyDiscountType: '',
        id: 0,
        isMoney: '',
        isSingle: '1',
        memberChargeWay: '',
        memberDiscount: 0,
        memberDiscountType: '',
        notes: '',
        price: 0,
        ticketName: '',
        userDiscount: 0,
        userDiscountType: '',
        voteType: '',
        votes: 0
      })
    },
    //删除
    delareaspeci(index) {
      this.form.activityPrices.splice(index, 1)
    },
    //删除活动封面图
    removeImgDianspeci(val) {
      this.form.activityImg = ''
    },
    //添加活动封面图
    uploadOneImgShowDianspeci(val) {
      this.form.activityImg = val
    },

    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.appLinkPicture = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.appLinkPicture = ''
    },
    // 保存
    clickSave() {
      this.disabled = true
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.participant = this.form.participant.toString()
          if (this.form.id == null) {
            save(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                const activityId = response.data.message
                let arr = []
                for (let i = 0; i < 5; i++) {
                  let obj = {
                    activityFormOptionList: [
                      {
                        activityFormId: null,
                        optionName: ''
                      }
                    ],
                    searchContent: '', // 搜索内容
                    isLimit: false, // 是否限制文件个数
                    limitMinimum: null, // 限制文件个数的最小值
                    limitMax: null, // 限制文件个数的最大值
                    fileFormatList: [], // 上传文件、图片格式
                    activityId,
                    hint: '', // 问题填写提示
                    id: null, // 问题id
                    isRequired: 0, // 是否必填;0代表非必填，1是代表必填
                    questionsExplain: '', // 问题说明
                    questionsTitle: '', // 标题，问题名字例如姓名
                    sort: 0, // 标题，问题名字例如姓名
                    type: 1 // 问题类型;1是单选，2附件上传，3多项选择，4图片上传，5下拉选择，6文字填空，7搜索选择
                  }
                  if (i === 0) {
                    obj.questionsTitle = '姓名'
                    obj.type = 6
                    obj.hint = '请输入' + obj.questionsTitle
                  } else if (i === 1) {
                    obj.questionsTitle = '性别'
                    obj.activityFormOptionList = [
                      {
                        activityFormId: null,
                        optionName: '男'
                      },
                      {
                        activityFormId: null,
                        optionName: '女'
                      }
                    ]
                    obj.type = 1
                  } else if (i === 2) {
                    obj.questionsTitle = '企业全称'
                    obj.type = 7
                    obj.hint = '请输入' + obj.questionsTitle
                  } else if (i === 3) {
                    obj.questionsTitle = '职位'
                    obj.type = 6
                    obj.hint = '请输入' + obj.questionsTitle
                  } else if (i === 4) {
                    obj.questionsTitle = '联系电话'
                    obj.type = 6
                    obj.hint = '请输入' + obj.questionsTitle
                  }
                  arr.push(obj)
                }
                insertActivityForm(arr).then((res) => {
                  if (res.data.resultCode == 200) {
                    this.disabled = false
                    this.$message.success('新增成功')
                    this.$router.go(-1)
                  } else {
                    this.disabled = false
                    this.$message.error(res.data.message)
                  }
                })
              } else {
                this.disabled = false
                this.$message.error(response.data.message)
              }
            })
          } else {
            update(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.disabled = false
                this.$message.success('编辑成功')
                this.$router.go(-1)
              } else {
                this.disabled = false
                this.$message.error(response.message)
              }
            })
          }
        } else {
          this.disabled = false
        }
      })
    },
    // 取消
    clickCancel() {
      // this.$router.back()
      this.$router.push({
        path: '/activity/list',
        query: {
          changeNum: true
        }
      })
      // console.log('取消')
    },
    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      // this.imageOneShow = value
      this.form.logo = value
    },

    //多个图片上传显示
    uploadOneImg(val) {
      this.form.image = val
    },
    // 移除
    removeImg(val) {
      this.form.image = ''
    }
  }
}
</script>
<style scoped lang="less">
.formBox {
  padding-top: 24px;
  width: 100%;
  height: 100%;
  .img_tips {
    font-size: 12px;
    color: #6ba1ff;
  }
  .h4_share {
    padding: 50px 50px 0px 30px;
    color: #146aff;
  }
  .tiket_flex {
    display: flex;
  }
  .ticket_Type {
    width: 600px;
    // height: 980px;
    padding: 20px 0px;
    border: 1px dashed #c4c4c4;
    border-radius: 2px;
    padding-left: 80px;
    min-height: 600px;
    .add_information {
      margin-left: 40px;
      width: 433.54px;
      min-height: 100px;
      border: 1px dashed #c4c4c4;
      border-radius: 2px;
      margin-bottom: 15px;
      .online_conference {
        .online_color {
          color: #999999;
        }
      }
      .online_conferences {
        margin-top: 16px;
        width: 447.76px;
        height: 305.17px;
        border: 1px dashed #c4c4c4;
        border-radius: 2px;
        margin-left: 120px;
        margin-bottom: 10px;
        .online_color {
          color: #999999;
        }
      }
    }
  }

  .inputBox {
    height: 40px;
    display: flex;
  }
  .general_inputs {
    width: 585px;
    border: 1px solid #d4d4d4;
    padding: 14px 20px;
    .input_flex {
      display: flex;
      justify-content: space-between;
      padding: 7px 0px;
      span {
        padding-left: 15px;
        font-size: 20px;
        cursor: pointer;
      }
      .el_buttom {
        position: relative;
        left: -110px;
      }
    }
  }

  /deep/.el-input {
    width: 600px;
    height: 40px;
  }

  .labenBox {
    width: 120px;
    display: flex;

    margin-right: 12px;

    .label {
      color: #333333;
      font-size: 14px;
      margin-right: 2px;
    }
    .span {
      color: #f05252;
    }
  }

  .logo_box {
    display: flex;
    .label_box {
      display: flex;
      margin-right: 12px;
      width: 120px;
      justify-content: flex-end;
      .logo_label {
        color: #333333;
        font-size: 14px;
        margin-right: 2px;
      }
      .span {
        color: #f05252;
      }
    }
  }

  .cover {
    display: flex;
  }

  .picker {
    width: 240px !important;
  }

  /deep/.el-radio__input {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__label {
    font-size: 14px;
    color: #333333;
  }

  .editInformation {
    margin-top: 40px;
    margin-left: 58px;
    color: #999999;
    font-size: 14px;

    .item {
      margin-bottom: 6px;
    }
  }
  .button_box {
    width: 100%;
    // height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ececec;
    padding-top: 15px;
    padding-bottom: 55px;
    .send {
      background-color: #f79646;
      color: #fff;
      cursor: pointer;
    }
    .preview {
      background-color: #37cc7e;
      color: #fff;
      cursor: pointer;
    }
    .save {
      background-color: #4e93fb;
      color: #fff;
      cursor: pointer;
    }
    .cancel {
      background-color: #fff;
      color: #4e93fb;
      cursor: pointer;
      border: 1px solid #4e93fb;
    }
    .button {
      width: 112px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
  }
  .editBarBox {
    width: 100%;
    display: flex;

    .editbar_text {
      width: 130px;
      display: flex;
      justify-content: flex-end;
      .text_a {
        margin-right: 2px;
      }
      .span {
        margin-right: 12px;
        color: #f05252;
      }
    }

    .editBar {
      // width: 686px;
      // width: 100%;
      width: 598.31px;
      height: 437px;
    }
  }
}
.inline_form_box {
  display: flex;
  // align-items: center;
  ::v-deep .el-input {
    color: #448aff;
    background: #ecf3ff;
    border-color: #b4d0ff;
    border: none;
    width: 217px;
  }
}
.informationType {
  ::v-deep .el-input__inner,
  ::v-deep .el-input {
    width: 154px;
  }
}
.cover {
  display: flex;
  flex-direction: column;
}
.thumbnail {
  display: flex;

  color: #bebebe;
}
.thumbnail img {
  width: 143.23px;
  height: 81.63px;
  border-radius: 4px;
}

.thumbnail div {
  margin-right: 20px;
}
.thumbnail_box div {
  display: flex;
  flex-direction: column;
  height: 30px;
}

::v-deep .el-button--primary.is-plain {
  color: #448aff;
  background: #ecf3ff;
  border-color: #b4d0ff;
  border: none;
}
::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 550px;
}
::v-deep .editor .text {
  border: 1px solid #ccc;
  min-height: 390px;
  max-height: 390px;
}
::v-deep .w-e-text-container .w-e-text {
  max-height: 390px;
}
::v-deep .txtree .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 600px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 600px;
  vertical-align: bottom;
  font-size: 14px;
}

::v-deep .original .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 180px;
  margin-bottom: 20px;
}
</style>
